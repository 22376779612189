@media screen and (max-width: 1280px) {
  .section {
    padding: 100px;
  }
}

@media screen and (max-width: 1024px) {
  .section {
    padding: 50px;
  }
}

@media screen and (max-width: 768px) {
  .section {
    display: block;
  }
}

@media screen and (max-width: 480px) {
  .section {
    padding: 50px 35px;
  }
}

@media screen and (max-width: 1024px) {
  .section-title {
    margin-right: 50px;
  }
}

@media screen and (max-width: 850px) {
  .section-title {
    width: 150px;
  }
}

@media screen and (max-width: 768px) {
  .section-title {
    text-align: left;
    width: auto;
  }

  .section-content {
    padding-top: 30px;
    padding-left: 50px;
  }

  .article {
    flex-direction: column-reverse;
  
    &-img {
      width: 100% !important;
      height: 100% !important;
      object-fit: scale-down !important;
    }
  
  }

}

@media screen and (max-width: 480px) {
  .section-content {
    padding-left: 30px;
  }
}

@media screen and (max-width: 630px) {
  .intro {
    padding: 120px 70px;
  }
}

@media screen and (max-width: 550px) {
  .intro {
    padding: 120px 50px;
  }
}

@media screen and (max-width: 480px) {
  .intro {
    padding: 70px 50px;
  }
}

@media screen and (max-width: 1024px) {
  .intro-hello,
  .intro-tagline {
    font-size: 2.25rem;
  }
}

@media screen and (max-width: 768px) {
  .intro-hello,
  .intro-tagline {
    font-size: 2rem;
  }
}

@media screen and (max-width: 550px) {
  .intro-hello,
  .intro-tagline {
    font-size: 1.75rem;
  }
}

@media screen and (max-width: 360px) {
  .intro-hello,
  .intro-tagline {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 330px) {
  .intro-hello,
  .intro-tagline {
    font-size: 1.4rem;
  }
}

.intro-hello .emoji,
.intro-tagline .emoji {
  width: 40px;
  height: 40px;
}

@media screen and (max-width: 1024px) {
  .intro-hello .emoji,
  .intro-tagline .emoji {
    width: 35px;
    height: 35px;
  }
}

@media screen and (max-width: 768px) {
  .intro-hello .emoji,
  .intro-tagline .emoji {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 360px) {
  .intro-hello .emoji,
  .intro-tagline .emoji {
    width: 25px;
    height: 25px;
  }
}

@media screen and (max-width: 550px) {
  .intro-contact {
    font-size: 1rem;
  }
}

@media screen and (max-width: 550px) {
  .intro-contact .emoji.pointer {
    width: 25px;
    height: 25px;
  }
}

@media screen and (max-width: 630px) {
  .skills .skill {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 630px) {
  .skills .skill-category {
    width: 47%;
    margin-right: 5px;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 480px) {
  .experience .job .time-place {
    display: block;
  }
}

@media screen and (max-width: 480px) {
  .experience .job-time {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1024px) {
  .footer {
    padding: 50px;
  }
}

@media screen and (max-width: 630px) {
  .footer {
    display: block;
  }
}

@media screen and (max-width: 480px) {
  .footer {
    padding: 50px 30px;
  }
}

@media screen and (max-width: 630px) {
  .footer-copyright {
    width: auto;
    text-align: center;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 630px) {
  .footer-copyright .bottom,
  .footer-copyright .top {
    display: inline-block;
  }
}

@media screen and (max-width: 480px) {
  .footer-copyright .bottom,
  .footer-copyright .top {
    display: block;
  }
}

@media screen and (max-width: 630px) {
  .footer-links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media screen and (max-width: 850px) {
  .footer-links a .text {
    display: none;
  }
}

@media screen and (max-width: 850px) {
  .footer-links svg {
    display: inline-block;
  }
}
