@import "./dark-mode.scss";
@import "./light-mode.scss";
@import "./vars.scss";
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: var(--font-color);
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  font-weight: 700;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.py-1 {
  padding: {
      top: .25rem;
      bottom: .25rem;
  }
}

.px-3 {
  padding: {
      left: .75rem;
      right: .75rem;
  }
}

.layout {
  min-height: 100vh;
  background: var(--background-color);
  transition: all 0.5s ease;
  color: var(--font-color);
}

.toggle-switch {
  .checkbox {
    opacity: 0;
    position: absolute;

    &:checked {
      + {
        .checkbox-label {
          .ball {
            transform: translateX(21px);
          }
        }
      }
    }
  }

  .checkbox-label {
    background-color: $white;
    width: 42px;
    height: 20px;
    border-radius: 50px;
    position: relative;
    padding: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $primary-border;
    box-shadow: inset 0px 0px 8px 0px $inset-box-shadow;

    .ball {
      background-color: $secondary-dark;
      width: 16px;
      height: 16px;
      position: absolute;
      left: 2px;
      right: 2px;
      border-radius: 50%;
      transition: transform 0.2s linear;
    }

    .fa-sun,
    .fa-moon {
      width: 11px;
    }
  }

  .fa-moon {
    color: $tertiary-dark;
  }

  .fa-sun {
    color: $secondary-light;
  }
}

.switch-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 30px;
}

.intro {
  padding: 120px 100px;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  max-width: 1440px;
  margin: 0 auto;
  &-hello {
    font-weight: 500;
  }

  .emoji {
    width: 40px;
    height: 40px;
  }

  .wave-hand {
    background-image: url(../assets/images/wave.png);
    margin-left: 10px;
    cursor: pointer;

    &.animated {
      animation: {
        duration: 1s;
        fill-mode: both;
      }
    }

    &:hover {
      animation-name: wave;
    }
  }

  .technologist {
    background-image: url(../assets/images/technologist.png);
    margin-left: 10px;
  }

  .finger-pointer {
    background-image: url(../assets/images/finger-pointer.png);
    margin: 0 5px;
    width: 30px;
    height: 30px;
    vertical-align: text-bottom;
  }

  &-tagline {
    font-weight: 300;
    max-width: 700px;
    span {
      font-weight: 600;
    }
  }

  &-hello,
  &-tagline {
    font-size: 2.5rem;
  }

  &-contact {
    font-size: 1.25rem;
    font-weight: 400;
  }
}

.highlight-link {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  font-weight: 400;
  text-decoration: none;
  display: inline-block;
  padding: 2px 3px;
  text-decoration: none;
  -webkit-box-shadow: inset 0 -3px 0 #007bff;
  box-shadow: inset 0 -3px 0 #007bff;

  &:hover {
    -webkit-box-shadow: inset 0 -33px 0 0 #007bff;
    box-shadow: inset 0 -33px 0 0 #007bff;
    color: #fff;
  }
}

.section {
  display: flex;
  justify-content: center;
  padding: 100px 170px;

  &-title {
    flex-shrink: 0;
    width: 200px;
    margin-right: 70px;
    color: #007bff;
    text-transform: uppercase;
    text-align: right;
    font-weight: 700;
    letter-spacing: 2px;
  }

  &-content {
    font-weight: 300;
    font-size: 1rem;
    width: 100%;
    max-width: 650px;

    p {
      margin: 0 0 25px 0;

      .underline-link {
        position: relative;
        display: inline-block;
        font-weight: 600;
        color: var(--font-color);
      }
    }
  }

  .skill {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    &-category {
      &-label {
        font-weight: 600;
        text-transform: uppercase;
        color: var(--font-color);
      }

      &-item {
        margin: 3px 0;
        font-size: 0.9rem;
      }
    }
  }

  .jobs {
    margin-bottom: 100px;
  }

  .job {
    margin-bottom: 25px;

    .time-place {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    &-.company {
      font-weight: 700;
    }
  }

  .article {
    display: flex;
    gap: 20px;
    margin-bottom: 50px;

    &-img {
      width: 200px;
      height: 150px;
      object-fit: contain;
    }

    &-preview {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
    }

    &-content {
      h3 {
        margin: 0;
      }
    }
  }
}

.footer {
  padding: 50px 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  &-copyright {
    font-weight: 600;
    width: 200px;
  }

  &-links {
    a {
      position: relative;
      display: inline-block;
      color: #007bff !important;
      text-transform: uppercase;
      font-weight: 700;
      margin: 0 15px;
      outline: 0;
    }
    svg {
      color: #007bff;
      display: none;
      width: 22px;
    }
  }

  .victory {
    background-image: url(../assets/images/victory.png);
    margin: 0 5px;
    width: 20px;
    height: 20px;
  }

  .top {
    margin-bottom: 10px;
  }
}

.emoji {
  display: inline-block;
  vertical-align: text-top;
  background: {
    size: contain;
    repeat: no-repeat;
    position: center;
  }
}

.category-chips {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin: 15px 0;
  span {
    // background-color: var(--background-color); // rgba(45, 212, 191, 0.1);
    // --tw-text-opacity: 1;
    color: rgb(0 123 255);
    border-radius: 4px;
    border: 1px solid #007bff;
    font: {
      size: 0.75rem;
      weight: 500;
    }
    text-transform: uppercase;
  }
}

@import "./animations.scss";

@import "./media.scss";
